import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import {
  AccountRecovery,
  AlreadyExist,
  AuthGuard,
  Cart,
  ChangePw,
  ComingSoon,
  Coupons,
  Events,
  FaQ,
  FindId,
  FindPw,
  Goods,
  GuestGuard,
  Home,
  Inquiry,
  Login,
  MakeInquiry,
  MobileLayout,
  MyInfo,
  MyPage,
  MyQnA,
  MyReview,
  NoticeDetail,
  Notices,
  NotificationSettings,
  OrderDetails,
  OrderList,
  OrderModificationSuccess,
  Page403,
  Page404,
  PageError,
  PaymentMethods,
  PaymentOrder,
  PaymentOrderSuccess,
  Points,
  Register,
  RegisterEnd,
  ResultDetail,
  ResultList,
  ResultReport,
  Results,
  Setting,
  Shop,
  SubSchedule,
  SubScriptionEdit,
  SubScriptions,
  Survey,
  Terms,
  UserGuide,
  Withdrawal,
} from './elements';
import { PATH_AUTH, PATH_PAGE, PATH_SUB, SUB_LINK } from './paths';

const routes: RouteObject[] = [
  {
    element: <MobileLayout />,
    children: [
      {
        errorElement: <PageError />,
        children: [
          {
            path: PATH_SUB.root,
            element: <Home />,
          },
          {
            path: PATH_PAGE.shop,
            element: <Shop />,
          },
          {
            path: PATH_PAGE.results,
            element: (
              <AuthGuard>
                <Results />
              </AuthGuard>
            ),
          },
          {
            path: PATH_PAGE.events,
            element: <Events />,
          },
          {
            path: PATH_PAGE.myPage,
            element: (
              <AuthGuard>
                <MyPage />
              </AuthGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    element: <MobileLayout needFooter={false} />,
    children: [
      {
        errorElement: <PageError />,
        children: [
          {
            path: PATH_AUTH.accountRecovery,
            element: (
              <GuestGuard>
                <AccountRecovery />
              </GuestGuard>
            ),
          },
          {
            path: PATH_AUTH.findId,
            element: (
              <GuestGuard>
                <FindId />
              </GuestGuard>
            ),
          },
          {
            path: PATH_AUTH.findPw,
            element: (
              <GuestGuard>
                <FindPw />
              </GuestGuard>
            ),
          },
          {
            path: PATH_AUTH.register,
            element: (
              <GuestGuard>
                <Register />
              </GuestGuard>
            ),
          },
          {
            path: PATH_AUTH.registerEnd,
            element: <RegisterEnd />,
          },
          {
            path: PATH_AUTH.existId,
            element: (
              <GuestGuard>
                <AlreadyExist />
              </GuestGuard>
            ),
          },
          {
            path: PATH_PAGE.goods,
            element: <Goods />,
          },
          {
            path: PATH_PAGE.cart,
            element: (
              <AuthGuard>
                <Cart />
              </AuthGuard>
            ),
          },
          {
            path: PATH_PAGE.paymentOrder,
            element: <PaymentOrder />,
          },
          {
            path: PATH_PAGE.paymentOrderSuccess,
            element: <PaymentOrderSuccess />,
          },
          {
            path: PATH_PAGE.terms,
            element: <Terms />,
          },
          {
            path: PATH_PAGE.userGuide,
            element: <UserGuide />,
          },
          {
            path: PATH_PAGE.myPage,
            children: [
              {
                path: SUB_LINK.faq,
                element: <FaQ />,
              },
              {
                path: SUB_LINK.qna.root,
                element: <MyQnA />,
              },
              {
                path: SUB_LINK.notices.root,
                element: <Notices />,
              },
              {
                path: SUB_LINK.notices.detail,
                element: <NoticeDetail />,
              },
              {
                path: SUB_LINK.inquiry.root,
                element: (
                  <AuthGuard>
                    <Inquiry />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.inquiry.create,
                element: (
                  <AuthGuard>
                    <MakeInquiry />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.setting.root,
                element: (
                  <AuthGuard>
                    <Setting />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.setting.myInfo,
                element: (
                  <AuthGuard>
                    <MyInfo />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.order.list,
                element: (
                  <AuthGuard>
                    <OrderList />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.order.details,
                element: (
                  <AuthGuard>
                    <OrderDetails />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.order.modificationSuccess,
                element: (
                  <AuthGuard>
                    <OrderModificationSuccess />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.subscription.root,
                element: (
                  <AuthGuard>
                    <SubScriptions />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.subscription.subSchedule,
                element: <SubSchedule />,
              },
              {
                path: SUB_LINK.subscription.editSub,
                element: <SubScriptionEdit />,
              },
              {
                path: SUB_LINK.subscription.payment,
                element: <PaymentMethods />,
              },
              {
                path: SUB_LINK.point.root,
                element: (
                  <AuthGuard>
                    <Points />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.coupon.root,
                element: (
                  <AuthGuard>
                    <Coupons />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.review.root,
                element: (
                  <AuthGuard>
                    <MyReview />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.survey.root,
                element: (
                  <AuthGuard>
                    <Survey />
                  </AuthGuard>
                ),
              },
            ],
          },
          {
            path: PATH_AUTH.login,
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            ),
          },
          {
            path: PATH_AUTH.changePw,
            element: <ChangePw />,
          },
          {
            path: SUB_LINK.setting.notification,
            element: <NotificationSettings />,
          },
          {
            path: PATH_AUTH.withdrawal,
            element: <Withdrawal />,
          },
          {
            path: PATH_PAGE.page404,
            element: <Page404 />,
          },
          {
            path: PATH_PAGE.page403,
            element: <Page403 />,
          },
          {
            path: PATH_PAGE.comingSoon,
            element: <ComingSoon />,
          },
          { path: '*', element: <Navigate to={PATH_PAGE.page404} replace /> },
          {
            path: PATH_PAGE.results,
            children: [
              {
                path: SUB_LINK.result.root,
                element: (
                  <AuthGuard>
                    <ResultList />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.result.detail,
                element: (
                  <AuthGuard>
                    <ResultDetail />
                  </AuthGuard>
                ),
              },
              {
                path: SUB_LINK.result.report,
                element: (
                  <AuthGuard>
                    <ResultReport />
                  </AuthGuard>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(routes, {
  basename: '/',
  future: {
    v7_fetcherPersist: true,
    v7_relativeSplatPath: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true, // v7의 동작 미리 적용
  },
});

export default router;
